import React from "react";
import styled from "styled-components";
import LazyLoad from "react-lazyload";
import image from "../../../assets/images/power-search.png";
import worksterImage from "../../../assets/images/workster.png";
import { colors, media } from "../../../constants/theme";
import { TrackClick } from "../../../utils/analytics";
import { Link } from "react-router-dom";
import { paths } from "../../../constants/paths";

const Wrapper = styled.div`
  background-color: ${colors.backgroundBlue};
`;

const Container = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 24px;
  padding-right: 24px;
  ${media.desktop`
    padding-top: 180px;
    padding-bottom: 240px;
    padding-left: 0;
    padding-right: 0;
    flex-direction: row;
  `}
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  ${media.desktop`
    padding: 60px;
  `}
`;
const SubHeading = styled.p`
  color: ${colors.linkBlue};
  margin-bottom: 8px;
  margin-top: 40px;
  ${media.desktop`
    margin-top: 0;
  `}
`;

const Heading = styled.h3`
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.05em;
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 1.6em;
`;

const Button = styled(Link)`
  background: ${colors.primaryRed};
  color: #fff;
  font-weight: 600;
  width: 240px;
  height: 50px;
  line-height: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const WorksterButton = styled.a`
  background: ${colors.primaryRed};
  color: #fff;
  font-weight: 600;
  width: 240px;
  height: 50px;
  line-height: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const Img = styled.img`
  width: 100%;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  margin-left: 16px;
  margin-right: 16px;
  ${media.tablet`
    max-width: 55%;
    margin-right: 0;
  `}
`;

const buttonCopy = "Discover Power Search";
const worksterButtonCopy = "Discover Workster";

export const PowerSearchHero = ({ tracking }) => (
  <div>
    <Wrapper>
      <Container>
        <LazyLoad offset={728} height={528} once>
          <Img src={worksterImage} alt="Workster" />
        </LazyLoad>
        <Content>
          <SubHeading>Workster</SubHeading>
          <Heading>Remote Jobs in the USA</Heading>
          <Text>
            We've built a new platform specifically for US residents to find
            remote work.
          </Text>
          <WorksterButton
            href="https://workster.co?ref=justremote"
            target="_blank"
            onClick={() => {
              TrackClick({
                category: "Workster",
                action: "Workster - Homepage - Bottom Hero",
                label: `Button - ${worksterButtonCopy}`,
              });
            }}
          >
            {worksterButtonCopy}
          </WorksterButton>
        </Content>
      </Container>
    </Wrapper>
    <Wrapper>
      <Container>
        <LazyLoad offset={728} height={528} once>
          <Img src={image} alt="Power Search" />
        </LazyLoad>
        <Content>
          <SubHeading>Power Search</SubHeading>
          <Heading>Find the jobs that don't get advertised</Heading>
          <Text>
            We've built a tool to help you discover all of the remote jobs that
            never get advertised.
          </Text>
          <Button
            to={paths.power_search}
            onClick={() => {
              TrackClick({
                category: "PowerSearch",
                action: tracking.action,
                label: `Button - ${buttonCopy}`,
              });
            }}
          >
            {buttonCopy}
          </Button>
        </Content>
      </Container>
    </Wrapper>
  </div>
);
